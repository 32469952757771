<template>
  <div>
    <div class="page-heading" style="margin-bottom: 0px;">
      <div class="row">
        <div class="col-lg-12">
          <div class="heading-content">
            <div class="heading-title">
              <h2>Fornecedores</h2>
            </div>
          </div>
          <button class="btn btn-primary pull-right ml-5 action-income" type="button" @click="createTransaction('create-provider-modal')"><span><i class="mdi mdi-plus-circle-outline"></i></span> Novo fornecedor </button>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-12">
        <div class="tab-content relative">
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-all'}" v-if="tabActive == 'tab-all'">
            <div class="card table-responsive longer" style="border-radius: 10px !important; margin-bottom: 0px;">
              <div class="row ng-scope" v-if="providers.length == 0">
                <br>
                <img :src="`assets/images/provider.svg`" class="nothing-image w-15p" style="width: 10% !important">
                <h3 class="nothing-text">Nenhum fornecedor cadastrado. </h3>
                <br>
                <br>
              </div>
              <div class="row"  v-if="providers.length > 0">
                <div class="col-sm-10">
                  Nome
                </div>
                <div class="col-sm-2"></div>
              </div>
              <hr  v-if="providers.length > 0">
              <ul class="list-group" v-if="providers.length > 0">
                <li class="list-group-item ng-scope" v-for="(item, i) in providers" :key="i">
                  <div class="row">
                    <div class="col-sm-10">
                      <span class="ng-binding">{{item.name}}</span>
                    </div>
                    <div class="col-sm-2 text-right">
                      <button type="button" class="btn-edit-category" @click="editProvider(item)">
                        <span>
                          <i class="fa fa-cog"></i>
                        </span>
                      </button>
                      <button type="button" class="btn-remove-category" @click="removeProvider(item.id)">
                        <span>
                          <i class="fa fa-trash"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <edit-provider-modal id="edit-provider-modal" @saveProvider="request"/>
    <goal-detail-modal id="goal-detail-modal" @updateGoal="request"/>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import { GaugeChart } from "echarts/charts";
import EditProviderModal from '@/components/EditProviderModal.vue';
import GoalDetailModal from '@/components/GoalDetailModal.vue';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
  GaugeChart,
]);

export default {
  name: 'Home',
  components: {
    GoalDetailModal,
    EditProviderModal,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      status: 'open',
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      monthStart: 0,
      cards: [],
      monthName: '',
      categories: [],
      wallets: [],
      filters: [
        {
          field: '',
          values: [
          ],
        },
      ],
      fields: [
        { label: 'Contas', value: 'account_id' },
        { label: 'Categoria', value: 'category' },
      ],
      filterAll: [
        { label: 'Cartão', value: 'card' },
        { label: 'Conta', value: 'account' },
      ],
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      tabActive: 'tab-all',
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      from: '',
      to: '',
      no_card: true,
      month: '',
      report: {},
      providers: [],
      isLoading: true,
      monthsList: [
        { id: '01', name: 'Janeiro', index: 0},
        { id: '02', name: 'Fevereiro', index: 1},
        { id: '03', name: 'Março', index: 2},
        { id: '04', name: 'Abril', index: 3},
        { id: '05', name: 'Maio', index: 4},
        { id: '06', name: 'Junho', index: 5},
        { id: '07', name: 'Julho', index: 6},
        { id: '08', name: 'Agosto', index: 7},
        { id: '09', name: 'Setembro', index: 8},
        { id: '10', name: 'Outubro', index: 9},
        { id: '11', name: 'Novembro', index: 10},
        { id: '12', name: 'Dezembro', index: 11},
      ],
      months: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ],
    };
  },
  provide: {
  },
  mounted () {
    window.scrollTo(0, 0);
    this.monthStart = new Date().getMonth();
  },
  created() {
    moment.locale('pt_BR');
    this.monthStart = new Date().getMonth();
    this.month      = new Date().getMonth();
    this.monthName  = moment(new Date()).format('MMMM');

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');

    this.$root.$on('register.provider', this.request);
    this.request();
  },
  methods: {
    deleteProvider(id) {
      AppService.deleteProvider(id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Fornecedor removido!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    removeProvider(id) {
      Swal.fire({
        title: `Remover fornecedor?`,
        text: 'Tem certeza que deseja remover esse fornecedor?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteProvider(id);
        }
      });
    },
    createTransaction(id) {
      this.$root.$emit('show.modal', id);
    },
    editProvider(item) {
      this.$root.$emit('show.modal', 'edit-provider-modal', item);
    },
    budgetDetail(id, item) {
      this.$root.$emit('show.modal', id, item);
    },
    request() {
      pace.start();
      AppService.getProvider().then(
        (response) => {
          if (response.providers) {
            this.providers = response.providers;
          }
          if (response.status == 301) {
            this.$router.push({ name: 'Premium', params: { expire: true }});
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
    },
  },
};
</script>

<style scoped lang="scss">
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 250px;
  top: -5px;
  left: 0;
  position: absolute;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
.list-budget .transaction-progress .progress {
  height: 8px;
}
.btn-edit-category {
  width: 40%;
}
.btn-remove-category {
  width: 40%;
}
.budget-buttons {
  position: absolute;
  top: 0;
  width: 120px;
  right: 0;
}
@media only screen and (max-width: 570px){
  .budget-buttons {
    top: -40px !important;
  }
}
.include-card-expense {
  position: absolute;
  left: 20px;
  z-index: 1;
}
.list-group-item {
}
.list-group-item:hover {
  background: #f9f9f9 !important;
}
.action-add {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
